<template>
  <div id="wrapper" v-if="isAuthenticated">
    <t-header></t-header>
    <t-side-nav></t-side-nav>
    <div class="page-content">
      <router-view />
      <t-footer></t-footer>
    </div>
  </div>
</template>

<script>
import Header from "@/view/layouts/Header";
import Footer from "@/view/layouts/Footer";
import SideNav from "@/view/layouts/SideNav";
//import "@/core/config/echo"
import {
  LOGIN,
  LOGOUT,
  SEND_SMS,
  SET_ERROR,
  GET_USER_WITH_TOKEN,
} from "@/core/services/store/auth.module";
export default {
  name: "DashboardLayout",
  components: {
    "t-header": Header,
    "t-footer": Footer,
    "t-side-nav": SideNav,
  },
  data() {
    return {
      isAuthenticated: false,
    };
  },
  mounted() {
    let token = this.secureLocalStorage("token");
    let access_token = new URLSearchParams(window.location.search).get("token");
    // if (this.$route.query?.token) {
    //   access_token = this.$route.query?.token;
    //   token = null
    // }
    if (access_token) {
      this.$store
        .dispatch(GET_USER_WITH_TOKEN, {
          url: "api/clients/authorization-token?token=" + access_token,
        })
        .then((response) => {
          if (response) {
            this.isAuthenticated = true
            let type = new URLSearchParams(window.location.search).get("type");
            let id = new URLSearchParams(window.location.search).get("id");
            // let isMyCertification = new URLSearchParams(window.location.search).get("my-certification");
            console.log('type', type);

            console.log(id);
            console.log(window.location.hash);

            if (type === 'assignment' && id) {
              this.$router.push({ name: "user.assignment-detail", params: { id: id } });
            } else if (type === 'education' && id) {
              this.$router.push({ name: "user.education-detail", params: { id: id } });
            } else if (type === 'education-category') {
              this.$router.push({ path: "/education-category" });
            } else if (type === "my-certification") {
              this.$router.push({ path: "/my-certification" });
            }
          } else {
            this.$router.push({ path: "/login" });
          }
        });
    } else {
      token ? (this.isAuthenticated = true) : null;
      let self = this;
      if (!token) {
        let url = window.location.href;
        if (!window.localStorage.getItem("redirect_url")) {
          window.localStorage.setItem("redirect_url", self.$route.fullPath);
        }
        this.$store
          .dispatch(LOGOUT)
          .then(() =>
            this.$router.push({
              name: "login",
              query: { redirect: self.$route.fullPath },
            })
          );
      }
    }
  },
};
</script>

<style scoped></style>
